import { getAuth, signInWithPopup, GoogleAuthProvider , signInWithEmailAndPassword} from 'firebase/auth';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";
import { getAnalytics ,logEvent} from "firebase/analytics";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAtKK12bn6RlIQC27fshXmrUT-D0dk7GuQ",
  authDomain: "aztech-consulting-llc.firebaseapp.com",
  projectId: "aztech-consulting-llc",
  storageBucket: "aztech-consulting-llc.appspot.com",
  messagingSenderId: "651987993786",
  appId: "1:651987993786:web:e20cc98ebb6fc801a8ee83",
  measurementId: "G-FFNWYFS6FZ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);
const analytics = getAnalytics(app);
export { getAuth, signInWithPopup, GoogleAuthProvider, db , storage, signInWithEmailAndPassword,analytics,logEvent};
