import SoftwareDev from "./Images/softwaredev.png";
import JavaProgramming from "./Images/javaprogramming.jpg"
import cibersecurity from "./Images/cibersecurity.jpg"
import Cloud from "./Images/cloud.jpg"
import data from "./Images/data.png"
import swe from "./Images/swe.jpg"
import consultancy from "./Images/consultancy.jpg"
import managed from "./Images/managed.jpg"


import PmiLogo from './Images/partners/pmi.png'
import MetaLogo from './Images/partners/MetaLogo.png'
import CourseraLogo from './Images/partners/Coursera.png'
import Salesforce from './Images/partners/Salesforce.png'
import GoogleLogo from './Images/partners/GoogleLogo.png'

export const WHAT_WE_DO = "/what-we-do";
export const WORK_WITH_US = "/work-with-us";
export const CONTACT_US = "/contact-us";
export const TRAINING_AND_PLACEMENT = "/training-and-placement";
export const ServicesData = [
  {
    name: "Software Development:",
    details: "At Aztech Consulting, we specialize in delivering innovative software solutions tailored to your business needs. Our team of experienced developers harnesses cutting-edge technologies and best practices to create custom applications that enhance efficiency, drive growth, and provide a competitive edge. From conceptualization to deployment, we ensure a seamless development process, focusing on quality, scalability, and user experience to meet and exceed your expectations.",
    techStack:
      "Technologies used (e.g., Java, .NET, Python, JavaScript frameworks).",
    icon: swe,
  },
  {
    name: "Cloud Services:",
    details: "Aztech Consulting offers comprehensive cloud services designed to optimize your business operations and drive innovation. Our expertise spans leading cloud platforms including AWS, Microsoft Azure, and Google Cloud, enabling us to deliver scalable and secure cloud solutions tailored to your needs. We provide end-to-end support in cloud migration, architecture design, and management, ensuring seamless integration with your existing systems. Our team is skilled in leveraging advanced cloud technologies such as containerization (Docker, Kubernetes), serverless computing, and DevOps practices to enhance agility, reduce costs, and improve operational efficiency.",
    techStack: "Expertise in AWS, Azure, Google Cloud.",
    icon: Cloud,
  },
  {
    name: "Cybersecurity:",
    details:
      "At Aztech Consulting, we prioritize your digital safety with cutting-edge cybersecurity solutions. Our team of experts specializes in safeguarding your business from evolving threats through a comprehensive range of services. We excel in implementing advanced security measures including intrusion detection systems, firewalls, and encryption protocols. Our expertise extends to vulnerability assessments, penetration testing, and security audits, ensuring robust protection for your data and systems. With a proactive approach to threat intelligence and incident response, we help you navigate the complexities of cybersecurity to maintain a secure and resilient IT environment.",
    techStack: "Implementation of security protocols and training.",
    icon: cibersecurity,
  },
  {
    name: "Data Analytics",
    details:
      "Aztech Consulting empowers your business with actionable insights through advanced data analytics solutions. Our team leverages sophisticated tools and techniques to transform raw data into strategic intelligence, helping you make informed decisions. We specialize in data visualization, predictive modeling, and performance metrics, providing clear, actionable insights that drive growth and efficiency. With expertise in data warehousing, ETL processes, and real-time analytics, we ensure that your data is accurately processed and analyzed to uncover trends, patterns, and opportunities tailored to your business needs.",
    techStack:
      "Use of tools like Power BI, Tableau, and custom analytics solutions.",
    icon: data,
  },
  {
    name: "IT Consulting",
    details: "Aztech Consulting provides expert IT consulting services to help you navigate the complexities of technology and align IT strategies with your business goals. Our experienced consultants offer comprehensive guidance on technology solutions, infrastructure optimization, and digital transformation. We assess your current IT environment, identify opportunities for improvement, and develop customized strategies to enhance efficiency, scalability, and performance. Whether you need support with IT strategy development, system integration, or project management, our team is dedicated to delivering solutions that drive innovation and achieve measurable results",
    techStack: "IT infrastructure assessment and planning.",
    icon: consultancy,
  },
  {
    name: "Managed IT Services",
    details: "Aztech Consulting offers comprehensive managed IT services designed to keep your technology running smoothly and efficiently. Our proactive approach ensures your IT infrastructure is well-maintained, secure, and optimized for performance. We provide round-the-clock monitoring, support, and management of your systems, including network security, data backup, and disaster recovery. By leveraging our expertise, you can focus on your core business activities while we handle the day-to-day IT operations, ensuring reliable performance, minimized downtime, and enhanced productivity. Our tailored solutions are designed to meet the unique needs of your organization and support your long-term success.",
    techStack: "24/7 monitoring and helpdesk support.",
    icon: managed,
  },
];

export const PlacementServices = [
  {
    name: "Resume Building",
    details:
      "Transform your career with Aztech Consulting's Resume Building Program! Our expert mentorship will guide you in crafting a standout resume that highlights your skills and achievements. Learn how to tailor your resume to pass through Applicant Tracking Systems (ATS), ensuring your application gets noticed by recruiters. Gain insights into optimizing keywords, formatting, and structuring your resume to align with job descriptions. With personalized feedback and support, you'll be well-equipped to present your best self to potential employers. Elevate your job search and advance your career with our comprehensive resume-building guidance!        ",
    icon: JavaProgramming,
  },
    {
    name: "Interview Preparation",
    details:
      "Ace your next interview with Aztech Consulting’s Interview Preparation Program! Our expert mentorship offers personalized coaching to help you confidently navigate interviews. From crafting compelling responses to common questions, to mastering the art of storytelling and presenting your achievements, we provide tailored guidance to enhance your performance. Practice with mock interviews, receive constructive feedback, and learn strategies to handle challenging scenarios. With our support, you’ll be well-prepared to make a lasting impression and secure your ideal role. Elevate your interview skills and achieve your career goals with our dedicated preparation program!      ",
    icon: JavaProgramming,
  },
];

export const SuccessStories = 
[
  {
    name: "How the trainning can help you to land your job!",
    details:
      "Our training program supports recent OPT graduates by providing critical tools for job search success in the U.S. We help enhance industry-specific skills, making candidates more competitive. Our resume and cover letter workshops ensure their applications stand out to employers. Through mock interviews and personalized feedback, graduates improve their interview skills and gain confidence. Networking opportunities and mentorship connect them with industry professionals and potential job leads. We also offer insights into the U.S. job market, including trends and employer expectations. Our training teaches how to optimize resumes for Applicant Tracking Systems (ATS), increasing visibility. Additionally, we focus on developing essential soft skills, such as communication and problem-solving, to ensure graduates are well-prepared for their new roles.    ",
    icon: JavaProgramming,
  },
    {
    name: " Comprehensive Solutions for Government Entities by Aztech Consulting",
    details:


    
      "  Aztech Consulting delivers tailored services to government entities, offering expertise in IT solutions, including software development and consulting, and robust data management and cloud hosting. We provide strategic management consulting to enhance operational efficiency and specialized services across various domains. Additionally, we offer reliable temporary staffing and security services, as well as training and development programs to boost professional skills. Our comprehensive approach ensures high-quality, customized solutions to meet the unique needs of government projects.   ",
    icon: JavaProgramming,
  },
];

export const Partner = [
  GoogleLogo,
  Salesforce,
  PmiLogo,
  CourseraLogo,
  MetaLogo
]

