import React, { useState, useEffect } from "react";
import Header from "../Components/Header";
import VideoHero from "../Components/VideoHero";
import Mission from "../Images/mission.jpg";
import Values from "../Images/values.jpg";
import Vision from "../Images/vision.jpg";
import Grid from "@mui/material/Grid";
import { styled } from "@mui/material/styles";
import Slider from "infinite-react-carousel";
import Paper from "@mui/material/Paper";
import Footer from "../Components/Footer";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));



const testimonials = [
    {
        client:"",
        description:
        `"I am thrilled with the services provided by Aztech Consulting. From the initial consultation to the final implementation, their team displayed an exceptional level of professionalism and expertise. They understood our needs perfectly and delivered tailored solutions that exceeded our expectations. Their attention to detail and commitment to excellence were evident in every aspect of their work. Thanks to Aztech Consulting, we've streamlined our processes and achieved remarkable improvements in efficiency. I highly recommend their services to anyone looking for top-notch consulting and support."`
      
    }
]

export default function LandingPage() {
  const [yearCount, setYearCount] = useState(0);
  const [clientCount, setClientCount] = useState(0);
  const [projectsCount, setProjectsCount] = useState(0);
  

  useEffect(() => {
    if (yearCount < 100) {
      setTimeout(() => {
        setYearCount(yearCount + 1);
      }, 50); // Adjust the timeout value for faster or slower animation
    }
  }, [yearCount]);

  useEffect(() => {
    if (clientCount < 100) {
      setTimeout(() => {
        setClientCount(clientCount + 1);
      }, 50); // Adjust the timeout value for faster or slower animation
    }
  }, [clientCount]);

  useEffect(() => {
    if (projectsCount < 100) {
      setTimeout(() => {
        setProjectsCount(projectsCount + 1);
      }, 50); // Adjust the timeout value for faster or slower animation
    }
  }, [projectsCount]);

  return (
    <div>
      <Header></Header>
      <VideoHero></VideoHero>
      {/* Mission Vision Block */}
      <h2 className="stats-heading" style={{ textAlign: "center" }}>
        Our core values 
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img src={Mission} alt="Mission Icon" className="mv-icon" />
            <h3 className="mv-title">MISSION</h3>
            <p className="mv-description">
            To empower businesses through comprehensive IT solutions and consulting services that enhance operational efficiency and drive innovation. <br /><br />
            We are dedicated to providing expert guidance, strategic insights, and reliable support to help our clients achieve their goals.


            </p>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img src={Vision} alt="Vision Icon" className="mv-icon" />
            <h3 className="mv-title">VISION</h3>
            <p className="mv-description">
            To be a trusted partner in technology transformation, recognized for our expertise, reliability, and commitment to helping businesses unlock new opportunities and achieve their full potential.
            </p>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <img src={Values} alt="Values Icon" className="mv-icon" />
            <h3 className="mv-title">VALUES</h3>
            <p className="mv-description">
            Customer Focus:<br />
      Prioritizing the needs and goals of our clients to deliver tailored and impactful solutions.<br /><br />
      Integrity:<br />
      Maintaining the highest ethical standards in all our business practices.<br /><br />
      Innovation:<br />
      Continuously exploring and implementing the latest technologies and methodologies.<br /><br />
      Collaboration:<br />
      Working together with clients and within our team to achieve common goals.<br /><br />
      Excellence:<br />
      Committing to high standards of quality and professionalism in every aspect of our work.
            </p>
          </Grid>
        </Grid>
      </Grid>
      <h2 className="stats-heading" style={{ textAlign: "center" }}>
        Getting the job done properly
      </h2>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="stat-item">
              <span className="stat-number">{14 + "+"}</span>
              <br></br>
              <span className="stat-description">years in business</span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="stat-item">
              <span className="stat-number">{50 + "+"}</span>
              <br></br>
              <span className="stat-description">Customers satisfied </span>
            </div>
          </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
          <Grid
            container
            direction={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <div className="stat-item">
              <span className="stat-number">{clientCount + "+"}</span>
              <br></br>
              <span className="stat-description">
                Projects delivered
              </span>
            </div>
          </Grid>
        </Grid>
      </Grid>
      
   
      <h2 className="stats-heading" style={{ textAlign: "center" }}>
        What our Clients Say
      </h2>
      <Slider autoplay={true} arrows={false}>
        <div style={{padding:20, background:"red"}}>
        {
            testimonials.map((testimonial)=>{
                return (
                    <>
                      <p className="testimonial">{testimonial.description}</p>
                    <p className="testimonial" style={{textAlign:'center'}}>{testimonial.client}</p>
                    </>
                  
                )
            })
        }
        </div>
  
    </Slider>
    <Footer></Footer>
    </div>
  );
}
