import React, { useEffect, useState, useLayoutEffect } from "react";
import Header from "../Components/Header";
import VideoHero from "../Components/VideoHero";
import Footer from "../Components/Footer";
import { PlacementServices, SuccessStories, Partner } from "../Data";
import { Grid } from "@mui/material";
import { getDataWithoutDocId } from "../Functions/functions";
import { Slider } from "infinite-react-carousel";
const ServiceDescription = (props) => {
  return (
    <Grid
      container
      direction={props.index % 2 == 0 ? "row" : "row-reverse"}
      style={{ marginBottom: 20 }}
    >
      <Grid item md={6} xs={12}>
        <h2 className="stats-heading" style={{ textAlign: "center" }}>
          {props.training.name}
          <p className="testimonial" style={{ textAlign: "center" }}>
            {props.training.details}
          </p>
          {props.training.duration && (
            <p className="testimonial" style={{ textAlign: "center" }}>
              Training Duration:{" "}
              {props.training.duration + " " + props.training.durationUnit}
            </p>
          )}
        </h2>
        {props.training.duration && (
          <Grid container justifyContent={"center"}>
            <button
              style={{
                height: 30,
                fontWeight: "bold",
                background: "#FFA500",
                border: "1px solid gray",
                borderRadius: 4,
                width: 150,
              }}
              onClick={() => {
                window.location.href = "/work-with-us";
              }}
            >
              Register Now
            </button>
          </Grid>
        )}
      </Grid>
      <Grid item md={6} xs={12}>
        <Grid container justifyContent={"center"}>
          <img
            src={props.training.icon}
            style={{ padding: 20, width: "80%" }}
          ></img>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default function TrainingAndPlacement() {
  const [allServices, setAllServices] = useState([]);
  const [stateUpdate, setStateUpdate] = useState(0);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      updateWindowWidth();
    };

    // Set up event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); //
  useEffect(() => {
    getDataWithoutDocId("TrainingServices").then((result) => {
      const allServices = [];
      result.forEach((doc) => {
        console.log("THis is the resulse", doc.data());
        const { name, details, date, icon, duration, durationUnit } =
          doc.data();
        allServices.push({
          name: name,
          details: details,
          date: date,
          icon: icon,
          duration: duration,
          durationUnit: durationUnit,
        });
        setAllServices(allServices);
        setStateUpdate(stateUpdate + 1);
      });
    });
  }, []);

  return (
    <>
      <Header></Header>
      <VideoHero></VideoHero>
      <h2
        className="stats-heading"
        style={{ textAlign: "center", fontSize: 34 }}
      >
        Training Programs
      </h2>
      {allServices?.map((training, index) => {
        return (
          <ServiceDescription
            training={training}
            index={index}
          ></ServiceDescription>
        );
      })}
      <h2
        className="stats-heading"
        style={{ textAlign: "center", fontSize: 34 }}
      >
        Placement Services
      </h2>
      {PlacementServices?.map((service, index) => {
        return (
          <ServiceDescription
            training={service}
            index={index}
          ></ServiceDescription>
        );
      })}
      <h2
        className="stats-heading"
        style={{ textAlign: "center", fontSize: 34 }}
      >
        Our Partners
      </h2>
      {windowWidth >= 900 && (
        <Grid container justifyContent={"space-evenly"}>
          {Partner.map((partnerLogo) => {
            return <img src={partnerLogo} style={{ width: 200 }}></img>;
          })}
        </Grid>
      )}
      {windowWidth > 500 && windowWidth < 900 && (
        <Slider autoplay={true} arrows={false}>
          {Partner.map((partnerLogo) => {
            return (
              <Grid container justifyContent={"center"} className="flex-grid">
                <img src={partnerLogo} style={{ width: "80%" }}></img>
              </Grid>
            );
          })}
        </Slider>
      )}
      {windowWidth <= 500 && (
        <Slider autoplay={true} arrows={false}>
          {Partner.map((partnerLogo) => {
            return <img src={partnerLogo} style={{ width: 200 }}></img>;
          })}
        </Slider>
      )}

      <h2
        className="stats-heading"
        style={{ textAlign: "center", fontSize: 34 }}
      >
         we work with  goverment contracts, business support <br /><br />

          and  professional development 
      </h2>
      {SuccessStories?.map((story, index) => {
        return (
          <ServiceDescription
            training={story}
            index={index}
          ></ServiceDescription>
        );
      })}

      <Footer></Footer>
    </>
  );
}
