import React, { useState, useEffect } from "react";
import { Paper, Grid } from "@mui/material";
import { signInWithEmailAndPassword, getAuth } from "../Functions/firebase";
import Swal from "sweetalert2";;

export default function LoginPage() {
  // declare firebase auth variable
  const auth = getAuth();
  const loginUser = () => {
    signInWithEmailAndPassword(auth, email, password).then((res) => {
      localStorage.setItem("azadminuid", res.user.uid);
      window.location.href = "/admin/training/services";
    }).catch(
      (error)=>{
        Swal.fire("Error","Invalid username and password!", "error");
      }
    )
  };

  const goBack = ()=>{
    window.location.href = "/"
  }
  // declare variable to save email
  const [email, setEmail] = useState("");
  // declare variable to save password
  const [password, setPassword] = useState("");
  return (
    <div>
      {/* <Header></Header>
      <VideoHero></VideoHero> */}
      <div id="recaptcha-container"></div>
      <Grid
        container
        style={{ height: "100vh" }}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Paper elevation={6} style={{ padding: 20, width: 300 }}>
          <Grid container direction={"column"} alignItems={"center"}>
            <h2>Login</h2>
            <input
              placeholder="UserName"
              className="userinput"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            ></input>
            <input
              placeholder="Password"
              type="password"
              className="userinput"
              value={password}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
            ></input>
            <button
              id="logout"
              type="button"
              className="button-active-large"
              onClick={() => {
                loginUser();
              }}
            >
              Login
            </button>
            <button
              id="logout"
              type="button"
              className="button-active-large"
              onClick={() => {
                goBack();
              }}
            >
              Back to Website
            </button>
          </Grid>
        </Paper>
      </Grid>
    </div>
  );
}
