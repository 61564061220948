import React, { useState } from "react";
import Header from "../Components/Header";
import VideoHero from "../Components/VideoHero";
import Footer from "../Components/Footer";
import { Grid, Paper, TextField } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {
  ref,
  uploadString,
  getDownloadURL,
  uploadBytes,
} from "firebase/storage";
import { storage } from "../Functions/firebase";
import CircularProgress from "@mui/material/CircularProgress";
import { saveDataWithoutDocId } from "../Functions/functions";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
const validator = require("email-validator");

const inputStyle = {
  width: "80%",
  marginTop: 20,
};
export default function WorkWithUs() {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState(false);
  const [captchaVerified, setCaptchVerified] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
  });

  const handleRecaptchaChange = (token) => {
    console.log("reCAPTCHA token:", token);
    // Add your verification logic here if needed
    if (token === null) {
      setCaptchVerified(false);
    } else {
      setCaptchVerified(true); // Set state to indicate reCAPTCHA verification success
    }
  };

  const submitFormData = () => {
    if (captchaVerified == false) {
      Swal.fire({
        title: "Errror!",
        text: "Please verify that you are a human!",
        icon: "error", // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: "Ok",
        confirmButtonColor: "#FFA50",
      });
      return;
    }
    if (
      formData.firstName == "" ||
      formData.lastName == "" ||
      formData.phone == "" ||
      formData.email == ""
    ) {
      Swal.fire({
        title: "Errror!",
        text: "Please Fill The Complete Form",
        icon: "error", // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: "Ok",
        confirmButtonColor: "#FFA50",
      });
      // alert("");
      return;
    } else if (!validator.validate(formData.email)) {
      Swal.fire({
        title: "Errror!",
        text: "Please enter a valid email!!",
        icon: "error", // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: "Ok",
        confirmButtonColor: "#FFA50",
      });
      return;
    }
    formData["createdAt"] = new Date();
    formData["resume"] = imageUrl;
    saveDataWithoutDocId("work-with-us", formData).then((doc) => {
      window.location.reload();
    });
  };

  const onFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const openFileLoadPopup = () => {
    var fileInput = document.getElementById("resumeId");
    fileInput.click();
  };

  const uploadImageToFirebase = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    console.log("THis is the fiel", file);
    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, `${uuidv4()}.jpeg`);

    uploadBytes(storageRef, file)
      .then((snapshot) => {
        // console.log("Uploaded an image!", snapshot);
        getDownloadURL(snapshot.ref)
          .then((downloadURL) => {
            setImageUrl(downloadURL);
            setLoading(false);
          })
          .catch((error) => {
            alert("Something went wrong while uploading image");
            setLoading(false);
            console.error("Error getting download URL:", error);
          });
      })
      .catch(function (error) {
        console.error("Error uploading file:", error);
      });
  };
  return (
    <>
      <Header></Header>
      <VideoHero></VideoHero>
      <h2 className="stats-heading" style={{ textAlign: "center" }}>
        Work with Us
      </h2>
      <Paper style={{ margin: 20, padding: 20 }}>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                id="outlined-helperText"
                label="First Name"
                value={formData.firstName}
                onChange={(e) => {
                  onFormChange("firstName", e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                id="outlined-helperText"
                label="Last Name"
                value={formData.lastName}
                onChange={(e) => {
                  onFormChange("lastName", e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                id="outlined-helperText"
                label="Phone Number"
                value={formData.phone}
                onChange={(e) => {
                  onFormChange("phone", e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                id="outlined-helperText"
                label="Email"
                value={formData.email}
                onChange={(e) => {
                  onFormChange("email", e.target.value);
                }}
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <input
                type="file"
                id="resumeId"
                style={{ display: "none" }}
                onChange={(e) => {
                  uploadImageToFirebase(e);
                }}
              ></input>
              <button
                style={{
                  height: 45,
                  fontWeight: "bold",
                  background: imageUrl ? "#1dbf73" : "#FFA500",
                  border: "1px solid gray",
                  borderRadius: 4,
                  color: "white",
                  marginTop: 20,
                  width: "80%",
                }}
                onClick={() => {
                  openFileLoadPopup();
                }}
              >
                {" "}
                {loading && (
                  <CircularProgress
                    size={24}
                    sx={{ position: "absolute" }}
                    style={{ marginLeft: -40 }}
                  />
                )}
                {imageUrl ? "Resume Uploaded" : "Upload Resume"}
              </button>
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"} style={{ marginTop: 10 }}>
              <ReCAPTCHA
                sitekey="6Lf-FQQqAAAAAAl8cBoYEgcO8-rSl0cXLCwGnyJD"
                onChange={handleRecaptchaChange}
              />
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container justifyContent={"center"} style={{ marginTop: 20 }}>
              <button
                style={{
                  height: 35,
                  fontWeight: "bold",
                  background: "#FFA500",
                  color: "white",
                  border: "1px solid gray",
                  borderRadius: 4,
                  width: 150,
                }}
                onClick={() => {
                  submitFormData();
                }}
              >
                Submit
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Footer></Footer>
    </>
  );
}
