import React from 'react'
import video from "../Videos/video.mp4";

export default function VideoHero() {
  return (
    <div class="video-hero">
    <div className="video-hero-player-container">
      <video className="video-hero-player" autoPlay muted loop>
        <source src={video} data-src={video} type="video/mp4"></source>
      </video>
    </div>
  </div>
  )
}
