import React from "react";
import Header from "../Components/Header";
import VideoHero from "../Components/VideoHero";
import Footer from "../Components/Footer";
import { ServicesData } from "../Data";
import Grid from "@mui/material/Grid";


export default function WhatWeDo() {
  return (
    <>
      <Header></Header>
      <VideoHero></VideoHero>
      <h2 className="stats-heading" style={{ textAlign: "center", fontSize:34 }}>
        Our Services
      </h2>
      <Grid container spacing={2} style={{padding:10}}>
        {ServicesData.map((service) => {
          return (
            <Grid item xs={12} lg={4}>
              <Grid
                container
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <img src={service.icon} alt="Mission Icon" className="mv-icon" />
                <h3 className="mv-title">{service.name}</h3>
                <p className="mv-description" style={{textAlign:"center"}}>
                 {service.details}
                </p>
              </Grid>
            </Grid>
          );
        })}
      </Grid>
      <Footer></Footer>
    </>
  );
}
