import React, { useState, useEffect } from "react";
import "../Styles/Table.css";
import { getDataWithoutDocId, removeData } from "../Functions/functions";
import { Grid } from "@mui/material";

import Swal from "sweetalert2";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import moment from "moment";
import ServicesEditCreateDialog from "../Components/ServicesEditCreateDialog";
import { getAuth } from "firebase/auth";
import AdminHeader from "../Components/AdminHeader";

const TrainingTable = () => {
  const [open, setOpen] = React.useState(false);
  const [allServices, setAllServices] = useState([]);
  const [allEvents, setAllEvents] = useState([]);
  const [stateUpdate, setStateUpdate] = useState(0);
  const [calendarMode, setCalendarMode] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    duration: "",
    durationUnit: "",
    details: "",
    date: "",
  });

  const onFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    
    getAuth().onAuthStateChanged((user) => {
      if (user) {
      } else {
       window.location.href= "/"
      }
    });
    getDataWithoutDocId("TrainingServices").then((result) => {
      const allServices = [];
      const allEventsData = [];
      result.forEach((doc) => {
        console.log("THis is the resulse", doc.data());
        var id = 1;
        const { name, details, date, icon, duration, durationUnit } =
          doc.data();
        allServices.push({
          id: doc.id,
          name: name,
          details: details,
          date: date,
          icon: icon,
          duration: duration,
          durationUnit: durationUnit,
        });
        allEventsData.push({
          id: id,
          title: name,
          start: new Date(date), // year, month (0-based), day, hour, minute
          end: new Date(date),
        });
        setAllEvents(allEventsData);
        setAllServices(allServices);
        setStateUpdate(stateUpdate + 1);
      });
    });
  }, []);

  const deleteRecord = (id) => {
    Swal.fire({
      title: "Warning!",
      text: "Are you sure you want to delte this record!!",
      icon: "error", // 'warning', 'error', 'success', 'info', 'question'
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        // Handle confirmed action
        removeData("TrainingServices", id).then((doc) => {
          Swal.fire("Deleted!", "Your data has been deleted.", "success");
          window.location.reload();
        });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Handle cancel action
        Swal.fire("Cancelled", "Your action has been cancelled.", "error");
      }
    });
  };

  const localizer = momentLocalizer(moment);
  return (
    <>
    <AdminHeader></AdminHeader>
      <Grid container style={{ marginBottom: 20 , marginTop:150}}>
        <Grid item md={6} sm={6}>
          <Grid container justifyContent={"flex-end"}>
            <button
              className="create-button"
              style={{
                height: 50,
                width: 200,
                borderBottomRightRadius: 0,
                borderTopRightRadius: 0,
                background:calendarMode?"gray":'#00cc7f'
              }}
              onClick={() => {
                setCalendarMode(false);
              }}
            >
              Table View
            </button>
          </Grid>
        </Grid>
        <Grid item md={6} sm={6}>
          <Grid container justifyContent={"flex-start"}>
            <button
              className="create-button"
              style={{
                height: 50,
                width: 200,
                borderBottomLeftRadius: 0,
                borderTopLeftRadius: 0,
                background:calendarMode?'#00cc7f':"gray"
              }}
              onClick={() => {
                setCalendarMode(true);
              }}
            >
              Calendar View
            </button>
          </Grid>
        </Grid>
      </Grid>

      {calendarMode == false && (
        <div className="user-table-container">
          <ServicesEditCreateDialog
            handleClose={handleClose}
            onFormChange={onFormChange}
            formData={formData}
            open={open}
          ></ServicesEditCreateDialog>
          <Grid container alignItems={"center"}>
            <Grid item md={10} sm={10}>
              <h1>Training Services</h1>
            </Grid>
            <Grid item md={2} sm={2}>
              <button className="create-button" onClick={handleClickOpen}>
                Create New Record
              </button>
            </Grid>
          </Grid>
          <table className="user-table">
            <thead>
              <tr>
                <th></th>
                <th>Icon</th>
                <th>Name</th>
                <th>Date</th>
                <th>Duration</th>
                <th>Duration Type</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {allServices.map((service, index) => (
                <tr key={index}>
                  <td>
                    <input type="checkbox" />
                  </td>
                  <td>
                    <div className="user-info">
                      <img src={service.icon} alt="Avatar" className="avatar" />
                    </div>
                  </td>
                  <td>{service.name}</td>
                  <td>{service.date}</td>
                  <td>{service.duration}</td>
                  <td>{service.durationUnit}</td>
                  <td>
                    <button
                      className="delete-button"
                      onClick={() => {
                        deleteRecord(service.id);
                      }}
                    >
                      Delete
                    </button>
                  </td>
                  <td>
                    <button
                      className="edit-button"
                      onClick={() => {
                        setFormData({
                          id: service.id,
                          name: service.name,
                          details: service.details,
                          date: service.date,
                          icon: service.icon,
                          duration: service.duration,
                          durationUnit: service.durationUnit,
                        });
                        handleClickOpen();
                      }}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {calendarMode && (
        <Calendar
          localizer={localizer}
          events={allEvents}
          startAccessor="start"
          endAccessor="end"
          views={["month", "week", "day"]}
          style={{
            margin: "20px",
            padding: "20px",
            backgroundColor: "#ffffff",
          }}
        />
      )}
    </>
  );
};

export default TrainingTable;
