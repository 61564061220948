import './App.css';
import './Styles/Stats.css'
import './Styles/MissionVision.css'
import { Route, BrowserRouter, Routes } from 'react-router-dom';
import LandingPage from './Pages/LandingPage';
import WhatWeDo from './Pages/WhatWeDo';
import WorkWithUs from './Pages/WorkWithUs';
import ContactUs from './Pages/ContactUs';
import TrainingAndPlacement from './Pages/TrainingAndPlacement';
import AdminLogin from './Pages/AdminLogin';
import TrainingServices from './Pages/TrainingServicesAdmin';

function App() {

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route exact path="/" element={<LandingPage />} />
          <Route exact path="/what-we-do" element={<WhatWeDo />} />
          <Route exact path="/work-with-us" element={<WorkWithUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/training-and-placement" element={<TrainingAndPlacement />} />
          <Route exact path="/admin" element={<AdminLogin />} />
          <Route exact path="/admin/training/services" element={<TrainingServices />} />
          
          
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
