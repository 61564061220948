import { setDoc, doc, getDoc, addDoc, collection, query, where, getDocs, deleteDoc, } from 'firebase/firestore';
import { db, getAuth, signInWithPopup, GoogleAuthProvider } from './firebase';

export async function saveData(collectionName, docId, jsonObject) {
  try {
    return await setDoc(doc(db, collectionName, docId), jsonObject, {
      merge: true,
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function removeData(collectionName, docId) {
  try {
    return await deleteDoc(doc(db, collectionName, docId));
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function saveDataWithoutDocId(collectionName, jsonObject) {
  try {
    return await addDoc(collection(db, collectionName), jsonObject);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function getData(collectionName, docId) {
  try {
    return await getDoc(doc(db, collectionName, docId));
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}

export async function getDataWithoutDocId(collectionName, condition) {
  console.log("THis is the condition", condition)
  try {
    let q = null;
    if (condition) {
      q = query(collection(db, collectionName), condition);
    } else {
      q = query(collection(db, collectionName));
    }

    return await getDocs(q);
  } catch (e) {
    console.error('Error adding document: ', e);
  }
}
