import React from "react";
import Grid from "@mui/material/Grid";
import FooterImage from "../Images/logo.png";
import Facebook from "../Images/facebook.png";
import Instagram from "../Images/instagram.png";
import Xlogo from "../Images/XLOGO.png";
import AZLogo from "../Images/azlogo.png";

import {
  WHAT_WE_DO,
  WORK_WITH_US,
  CONTACT_US,
  TRAINING_AND_PLACEMENT,
} from "../Data";

const addressDiv = {
  textAlign: "left",
  marginTop: "0px",
  padding: "10px",
  color: "white",
};
const addressDivSmall = {
  textAlign: "left",
  padding: "10px",
  color: "white",
};
const socialIcons = {
  marginTop: "250px",
  textAlign: "center",
};
const socialIconsSmall = {
  marginTop: "50px",
  textAlign: "center",
};

export default function Footer(props) {
  const handleRedirection = (link) => {
    window.location.href = link;
  };
  return (
    <>
      <Grid container justify="center" style={{ background: "black" }}>
        <Grid item md={4} sm={6} xs={12}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems={"center"}
          >
            <img
              src={AZLogo}
              style={{ height: 220, width: 220, marginTop: "20px" }}
              alt="footer"
            ></img>
          </Grid>
        </Grid>
        <Grid item md={4} sm={6} xs={12}>
          {" "}
          <Grid
            container
            direction={"column"}
            style={props.viewWidth >= 960 ? addressDiv : addressDivSmall}
          >
            <div>
              <h3>Our Company</h3>
              <p>AZTech Consulting LLC </p>
              <p>5000 CentreGreen Way, Suite 500, North Carolina</p>
              <p> Cary,NC </p>
              <p>everson.moura@aztechconsulting.net</p>
              <p>(720)-239-2382</p>
            </div>
          </Grid>
        </Grid>
        <Grid item md={4} sm={6} xs={12} className="hide-on-small-screen">
          <Grid
            container
            direction={"column"}
            style={{ background: "black", paddingTop: 20 }}
          >
            <Grid item xs={10} sm={6} md={4} lg={3} alignContent="center">
              <a href={WHAT_WE_DO} class="a-footer">
                What we do?
              </a>
            </Grid>
            <Grid item xs={10} sm={6} md={4} lg={3} alignContent="center">
              <a href={WORK_WITH_US} class="a-footer">
                Work with us
              </a>
            </Grid>
            <Grid item xs={10} sm={6} md={4} lg={3} alignContent="center">
              <a href={CONTACT_US} class="a-footer">
                Contact Us
              </a>
            </Grid>
            <Grid item xs={10} sm={6} md={4} lg={3} alignContent="center">
              <a href={TRAINING_AND_PLACEMENT} class="a-footer">
                Training & Placement
              </a>
            </Grid>
          </Grid>
          <Grid
            container
            style={props.viewWidth >= 960 ? socialIcons : socialIconsSmall}
          >
            <a href="">   
              <img
                src={Facebook}
                style={{ height: "50px", marginRight: "10px" }}
              ></img>
            </a>
            <a href="">
              <img
                src={Instagram}
                style={{ height: "50px", marginRight: "10px" }}
              ></img>
            </a>
            <a href="">
              <img src={Xlogo} style={{ height: "50px" }}></img>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid container style={{ background: "black", paddingTop:10 }} columnSpacing={4} className="hide-on-large-screen">
        <Grid item sm={6} xs={6}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems={"center"}
          >
            <a href={WHAT_WE_DO} class="a-footer">
              What we do?
            </a>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={6}>
          <a href={WORK_WITH_US} class="a-footer">
            Work with us
          </a>
        </Grid>
        <Grid item sm={6} xs={6}>
          <Grid
            container
            direction="column"
            justify="center"
            alignItems={"center"}
          >
            <a href={CONTACT_US} class="a-footer">
              Contact Us
            </a>
          </Grid>
        </Grid>
        <Grid item sm={6} xs={6}>
          <a href={TRAINING_AND_PLACEMENT} class="a-footer">
            Trainings
          </a>
        </Grid>
        <Grid
            container
            justifyContent={"space-evenly"}
          >
            <a href="">
              <img
                src={Facebook}
                style={{ height: "50px", marginRight: "10px" }}
              ></img>
            </a>
            <a href="">
              <img
                src={Instagram}
                style={{ height: "50px", marginRight: "10px" }}
              ></img>
            </a>
            <a href="">
              <img src={Xlogo} style={{ height: "50px" }}></img>
            </a>
          </Grid>
      </Grid>
    </>
  );
}
