import React , {useState} from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import CircularProgress from "@mui/material/CircularProgress";
import { Grid, TextField, MenuItem } from "@mui/material";

import { ref, getDownloadURL, uploadBytes } from "firebase/storage";
import { v4 as uuidv4 } from "uuid";
import {
    saveData,
    saveDataWithoutDocId,
  } from "../Functions/functions";
  import Swal from "sweetalert2";

import { storage } from "../Functions/firebase";

export default function ServicesEditCreateDialog(props) {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(false);
  const submitFormData = () => {
    if (
      props.formData.name == "" ||
      props.formData.details == "" ||
      props.formData.duration == "" ||
      props.formData.date == "" ||
      props.formData.durationUnit == ""
    ) {
      Swal.fire({
        title: "Errror!",
        text: "Please Fill The Complete Form",
        icon: "error", // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: "Ok",
        confirmButtonColor: "#FFA50",
      });
      return;
    } else if (imageUrl == false) {
      Swal.fire({
        title: "Errror!",
        text: "Please upload a file!!",
        icon: "error", // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: "Ok",
        confirmButtonColor: "#FFA50",
      });
      return;
    }
    props.formData["createdAt"] = new Date();
    if (imageUrl != false) {
      props.formData["icon"] = imageUrl;
    }

    saveDataWithoutDocId("TrainingServices", props.formData).then((doc) => {
      window.location.reload();
    });
  };

  const updateFormData = () => {
    if (
      props.formData.name == "" ||
      props.formData.details == "" ||
      props.formData.duration == "" ||
      props.formData.date == "" ||
      props.formData.durationUnit == ""
    ) {
      Swal.fire({
        title: "Errror!",
        text: "Please Fill The Complete Form",
        icon: "error", // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: "Ok",
        confirmButtonColor: "#FFA50",
      });
      return;
    } else if (imageUrl == false && props.formData.icon == null) {
      Swal.fire({
        title: "Errror!",
        text: "Please upload a file!!",
        icon: "error", // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: "Ok",
        confirmButtonColor: "#FFA50",
      });
      return;
    }
    props.formData["createdAt"] = new Date();
    props.formData["icon"] = imageUrl;

    saveData("TrainingServices", props.formData.id, props.formData).then((doc) => {
      window.location.reload();
    });
  };

  const uploadImageToFirebase = (event) => {
    setLoading(true);
    const file = event.target.files[0];
    console.log("THis is the fiel", file);
    // Create a reference to the file in Firebase Storage
    const storageRef = ref(storage, `${uuidv4()}.jpeg`);

    uploadBytes(storageRef, file)
      .then((snapshot) => {
        // console.log("Uploaded an image!", snapshot);
        getDownloadURL(snapshot.ref)
          .then((downloadURL) => {
            setImageUrl(downloadURL);
            setLoading(false);
          })
          .catch((error) => {
            alert("Something went wrong while uploading image");
            setLoading(false);
            console.error("Error getting download URL:", error);
          });
      })
      .catch(function (error) {
        console.error("Error uploading file:", error);
      });
  };


  const inputStyle = {
    width: "90%",
    marginTop: 20,
  };

  const options = [
    { value: "Minutes", label: "Minutes" },
    { value: "Hours", label: "Hours" },
    { value: "Days", label: "Days" },
    { value: "Months", label: "Months" },
  ];

  const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

  const openFileLoadPopup = () => {
    var fileInput = document.getElementById("resumeId");
    fileInput.click();
  };

  return (
    <Dialog
      open={props.open}
      maxWidth="sm"
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      onClose={props.handleClose}
      aria-describedby="alert-dialog-slide-description"
    >
      <DialogTitle>{"Training Services Infromation"}</DialogTitle>
      <DialogContent>
        <Grid container direction="column" alignItems={"center"}>
          <TextField
            style={inputStyle}
            id="outlined-helperText"
            label="Name"
            value={props.formData.name}
            onChange={(e) => {
              props.onFormChange("name", e.target.value);
            }}
          />

          <input
            type="date"
            style={{
              height: 45,
              width: "88.5%",
              marginTop: 20,
              borderRadius: 4,
            }}
            value={props.formData.date}
            onChange={(e) => {
              props.onFormChange("date", e.target.value);
            }}
          ></input>

          <TextField
            style={inputStyle}
            id="outlined-helperText"
            label="Duration"
            value={props.formData.duration}
            onChange={(e) => {
              props.onFormChange("duration", e.target.value);
            }}
          />
          <TextField
            select
            style={inputStyle}
            id="outlined-helperText"
            label="Duration Type"
            value={props.formData.durationUnit}
            onChange={(e) => {
              props.onFormChange("durationUnit", e.target.value);
            }}
          >
            {options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            style={inputStyle}
            id="outlined-helperText"
            label="Details"
            multiline
            value={props.formData.details}
            onChange={(e) => {
              props.onFormChange("details", e.target.value);
            }}
          />
          <input
            type="file"
            id="resumeId"
            style={{ display: "none" }}
            onChange={(e) => {
              uploadImageToFirebase(e);
            }}
          ></input>
          <button
            style={{
              height: 45,
              fontWeight: "bold",
              background: imageUrl ? "#1dbf73" : "#FFA500",
              border: "1px solid gray",
              borderRadius: 4,
              color: "white",
              marginTop: 20,
              width: "90%",
            }}
            onClick={() => {
              openFileLoadPopup();
            }}
          >
            {" "}
            {loading && (
              <CircularProgress
                size={24}
                sx={{ position: "absolute" }}
                style={{ marginLeft: -40 }}
              />
            )}
            {imageUrl ? "Image Uploaded" : "Upload Image"}
          </button>
          {props.formData.id ? (
            <button
              style={{
                height: 45,
                fontWeight: "bold",
                background: "#FFA500",
                color: "white",
                border: "1px solid gray",
                borderRadius: 4,
                marginTop: 20,
                width: "90%",
              }}
              onClick={() => {
                updateFormData();
              }}
            >
              Update Data
            </button>
          ) : (
            <button
              style={{
                height: 45,
                fontWeight: "bold",
                background: "#FFA500",
                color: "white",
                border: "1px solid gray",
                borderRadius: 4,
                marginTop: 20,
                width: "90%",
              }}
              onClick={() => {
                submitFormData();
              }}
            >
              Submit
            </button>
          )}
        </Grid>
      </DialogContent>
      <DialogActions>
        {/* <Button onClick={props.handleClose}>Disagree</Button>
    <Button onClick={props.handleClose}>Agree</Button> */}
      </DialogActions>
    </Dialog>
  );
}
