import React, { useLayoutEffect, useState } from "react";
import Logo from "../Images/logo.png";
import { Grid, IconButton } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { WHAT_WE_DO,WORK_WITH_US,CONTACT_US,TRAINING_AND_PLACEMENT } from "../Data";
import { getAuth } from "firebase/auth";

export default function AdminHeader(props) {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const updateWindowWidth = () => {
    setWindowWidth(window.innerWidth);
  };

  useLayoutEffect(() => {
    // Function to handle window resize
    const handleResize = () => {
      updateWindowWidth();
    };

    // Set up event listener
    window.addEventListener("resize", handleResize);

    // Clean up event listener on component unmount
    return () => window.removeEventListener("resize", handleResize);
  }, []); //

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirection = (link) => {
    window.location.href = link;
  };

  const handleLogout = () => {
    getAuth().signOut().then(() => {
      // Sign-out successful.
      localStorage.removeItem("azadminuid")
      window.location.reload()
      // Optionally, redirect or update your application state
    }).catch((error) => {
      // An error happened.
      console.error('Error signing out:', error);
    });
  };

  return (
    <nav className="navbar" style={{background:'black'}}>
      {windowWidth >= 900 ? (
        <>
          <a href="/">
            <img src={Logo} width={215}></img>
          </a>
          <a href={WHAT_WE_DO}>What we do?</a>
          <a href={WORK_WITH_US}>Work with us?</a>
          <a href={CONTACT_US}>Contact Us?</a>
          <a href={TRAINING_AND_PLACEMENT}>Trainings</a>{" "}
          <a onClick={()=>{
            handleLogout()
          }} >Logout</a>{" "}
        </>
      ) : (
        <Grid container>
          <Grid item sm={10} xs={10}>
            <a href="/">
              <img src={Logo} width={150}></img>
            </a>
          </Grid>
          <Grid item sm={2}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleClick}
              edge="start"
              // className={clsx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            {" "}
            <MenuItem
              onClick={() => {
                handleRedirection(WHAT_WE_DO);
              }}
            >
              {" "}
              <a>What we do?</a>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRedirection(WORK_WITH_US);
              }}
            >
              <a>Work with us?</a>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRedirection(CONTACT_US);
              }}
            >
              <a>Contact Us?</a>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleRedirection(TRAINING_AND_PLACEMENT);
              }}
            >
              <a>Training and Placement?</a>
            </MenuItem>
          </Menu>
        </Grid>
      )}
    </nav>
  );
}
