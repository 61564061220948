import React, { useState } from "react";
import Header from "../Components/Header";
import VideoHero from "../Components/VideoHero";
import Footer from "../Components/Footer";
import { Grid, Paper, TextField } from "@mui/material";
import { saveDataWithoutDocId } from "../Functions/functions";
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';

const validator = require("email-validator");

const inputStyle = {
  width: "80%",
  marginTop: 20,
};
export default function ContactUs() {

  const [captchaVerified, setCaptchVerified] = useState(false);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",
    address: "",
    state: "",
    city: "",
    country: "",
    message: "",
  });

  const submitContactInformation = () => {
    if (captchaVerified == false) {
      Swal.fire({
        title: "Errror!",
        text: "Please verify that you are a human!",
        icon: "error", // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: "Ok",
        confirmButtonColor: "#FFA50",
      });
      return;
    }
    if (
      formData.firstName == "" ||
      formData.lastName == "" ||
      formData.phone == "" ||
      formData.email == "" ||
      formData.address == "" ||
      formData.city == "" ||
      formData.country == "" ||
      formData.message == ""
    ) {
      Swal.fire({
        title: 'Errror!',
        text: 'Please Fill The Complete Form',
        icon: 'error', // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: 'Ok',
      });
      return;
    }
    else if (!validator.validate(formData.email)) {
      Swal.fire({
        title: 'Errror!',
        text: 'Please enter a valid email!!',
        icon: 'error', // 'warning', 'error', 'success', 'info', 'question'
        confirmButtonText: 'Ok'
      });
      return;
    }
    formData["createdAt"] = new Date();
    saveDataWithoutDocId("contact-us", formData).then((doc) => {
      window.location.reload();
    });
  };

  const onFormChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  
  const handleRecaptchaChange = (token) => {
    console.log("reCAPTCHA token:", token);
    // Add your verification logic here if needed
    if (token === null) {
      setCaptchVerified(false);
    } else {
      setCaptchVerified(true); // Set state to indicate reCAPTCHA verification success
    }
  };

  return (
    <>
      <Header></Header>
      <VideoHero></VideoHero>
      <h2 className="stats-heading" style={{ textAlign: "center" }}>
        Contact Us
      </h2>
      <Paper style={{ margin: 20, padding: 20 }}>
        <Grid container>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                id="outlined-helperText"
                value={formData.firstName}
                onChange={(e) => {
                  onFormChange("firstName", e.target.value);
                }}
                label="First Name"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                value={formData.lastName}
                onChange={(e) => {
                  onFormChange("lastName", e.target.value);
                }}
                id="outlined-helperText"
                label="Last Name"
              />
            </Grid>
          </Grid>

          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                value={formData.phone}
                onChange={(e) => {
                  onFormChange("phone", e.target.value);
                }}
                id="outlined-helperText"
                label="Phone Number"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                value={formData.email}
                onChange={(e) => {
                  onFormChange("email", e.target.value);
                }}
                id="outlined-helperText"
                label="Email"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                id="outlined-helperText"
                value={formData.address}
                onChange={(e) => {
                  onFormChange("address", e.target.value);
                }}
                label="Address"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                value={formData.state}
                onChange={(e) => {
                  onFormChange("state", e.target.value);
                }}
                id="outlined-helperText"
                label="State"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                value={formData.city}
                onChange={(e) => {
                  onFormChange("city", e.target.value);
                }}
                id="outlined-helperText"
                label="City"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                value={formData.country}
                onChange={(e) => {
                  onFormChange("country", e.target.value);
                }}
                id="outlined-helperText"
                label="Country"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"}>
              <TextField
                style={inputStyle}
                id="outlined-helperText"
                value={formData.message}
                onChange={(e) => {
                  onFormChange("message", e.target.value);
                }}
                label="Message"
              />
            </Grid>
          </Grid>
          <Grid item md={6} xs={12}>
            <Grid container justifyContent={"center"} style={{ marginTop: 10 }}>
              <ReCAPTCHA
                sitekey="6Lf-FQQqAAAAAAl8cBoYEgcO8-rSl0cXLCwGnyJD"
                onChange={handleRecaptchaChange}
              />
            </Grid>
          </Grid>
          <Grid item md={12} xs={12}>
            <Grid container justifyContent={"center"} style={{ marginTop: 20 }}>
              <button
                style={{
                  height: 35,
                  fontWeight: "bold",
                  background: "#FFA500",
                  color:'white',
                  border: "1px solid gray",
                  borderRadius: 4,
                  width: 150,
                }}
                onClick={() => {
                  submitContactInformation();
                }}
              >
                Submit
              </button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Footer></Footer>
    </>
  );
}
